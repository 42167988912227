import Img, { FluidObject } from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Image = styled(Img)<{ fluid: FluidObject | FluidObject[] }>`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 50% 50% !important;
  }
`;

type Props = {
  fluid: FluidObject | FluidObject[];
};

export const Background: FC<Props> = ({ fluid, children }) => (
  <Wrapper>
    {children}
    <Image loading="eager" fluid={fluid} />
  </Wrapper>
);
