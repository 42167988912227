// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dobro-dosli-tsx": () => import("./../../../src/pages/dobro-dosli.tsx" /* webpackChunkName: "component---src-pages-dobro-dosli-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-izlozbeni-prostor-tsx": () => import("./../../../src/pages/izlozbeni-prostor.tsx" /* webpackChunkName: "component---src-pages-izlozbeni-prostor-tsx" */),
  "component---src-pages-politika-privatnosti-tsx": () => import("./../../../src/pages/politika-privatnosti.tsx" /* webpackChunkName: "component---src-pages-politika-privatnosti-tsx" */)
}

