import React, { FC } from 'react';

import { GlobalStyle } from '~src/styles';

const AppLayout: FC = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <main>{children}</main>
    </>
  );
};

export default AppLayout;
