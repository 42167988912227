import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const LogoSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 73.06 56"
        className={className}
      >
        <defs>
          <style>
            {
              '.cls-aco-1{fill:#c00}.cls-aco-2{fill:#fff;stroke:#e30613;stroke-width:.11px;stroke-miterlimit:3.86}'
            }
          </style>
        </defs>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <path
              className="cls-aco-1"
              d="M0 0L22.94 0 22.94 56 0 56 0 0 0 0z"
            />
            <path
              className="cls-aco-2"
              d="M6 50.81a9 9 0 00-.32 2.5H2.37A22.61 22.61 0 013 47a23.15 23.15 0 015.33-9.93 10.57 10.57 0 013.1-2.79 13.85 13.85 0 013.32 3.09A24.89 24.89 0 0119.9 47a26 26 0 01.71 6.23h-3.36a9.31 9.31 0 00-.25-2.42z"
            />
            <path
              className="cls-aco-1"
              d="M6.71 47.37h9.47a17.31 17.31 0 00-1.63-4.24A16.31 16.31 0 0011.34 39a13.85 13.85 0 00-3 3.95 18.88 18.88 0 00-1.62 4.39z"
            />
            <path
              className="cls-aco-1"
              d="M24.96 0L48.06 0 48.06 56 24.96 56 24.96 0 24.96 0z"
            />
            <path
              className="cls-aco-2"
              d="M45.16 50.24a10.19 10.19 0 01-7.84 3.65A9.83 9.83 0 1145.09 38l-2.72 2.1a6.25 6.25 0 00-5-2.34 6.28 6.28 0 000 12.55 6.53 6.53 0 005-2.24L45 50l.18.2z"
            />
            <path
              className="cls-aco-1"
              d="M50.08 0L73.06 0 73.06 56 50.08 56 50.08 0 50.08 0z"
            />
            <path
              className="cls-aco-2"
              d="M51.82 44a9.72 9.72 0 019.71-9.72A9.85 9.85 0 0171.4 44a10 10 0 01-9.87 9.83A9.82 9.82 0 0151.82 44z"
            />
            <path
              d="M55.3 44a6.27 6.27 0 0112.54 0 6.27 6.27 0 11-12.54 0z"
              stroke="#c00"
              strokeWidth=".06px"
              strokeMiterlimit={3.86}
              fill="#c00"
            />
          </g>
        </g>
      </svg>
    );
  }
);
