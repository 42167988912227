import m1 from '~src/assets/images/menu/1csm_pesacke_zone_sema.jpg';
import m2 from '~src/assets/images/menu/2csm_ACO-Stormbrixx-Systemuebersicht-Headerbild.jpg';
import m3 from '~src/assets/images/menu/3csm_Bild_Privathaeuser.jpg';
import m4 from '~src/assets/images/menu/4_menu.jpg';
import m5 from '~src/assets/images/menu/5csm_tray_naslovna.jpg';
import m6 from '~src/assets/images/menu/6ACO_Quatrix_protivpovratna_klapna.jpg';
import m7 from '~src/assets/images/menu/7csm_ACO_Spin_krovni_slivnici_od_livenog_gvozdja.jpg';
import m8 from '~src/assets/images/menu/8Foto-Header-ACO-Parkdeckrinne-Deckline.jpg';
import m9 from '~src/assets/images/menu/9csm_ACO_LipuSmart_Hero.jpg';
import m10 from '~src/assets/images/menu/10menu.jpg';
import m11 from '~src/assets/images/menu/11ACOfamilywall.jpg';

export const menuList = [
  {
    id: 1,
    label: `Odvodnjavanje u niskogradnji`,
    image: m1,
    sceneID: `1_aco`,
    coords: {
      yaw: -2.8029750666669226,
      pitch: -0.051909595874604975,
    },
  },
  {
    id: 2,
    label: `Infiltracija i retenzija`,
    image: m2,
    sceneID: `1_aco`,
    coords: {
      yaw: -0.6464120653512122,
      pitch: 0.176720399967369,
    },
  },
  {
    id: 3,
    label: `Proizvodi za kuću, okućnicu i baštu`,
    image: m3,
    sceneID: `2_aco`,
    coords: {
      yaw: 3.0813874316984187,
      pitch: 0.15027587294062705,
    },
  },
  {
    id: 4,
    label: `Kupatilski program`,
    image: m4,
    sceneID: `3_aco`,
    coords: {
      yaw: 3.091112051391347,
      pitch: -0.05268965794953573,
    },
  },
  {
    id: 5,
    label: `Komercijalne kuhinje i prehrambena industrija`,
    image: m5,
    sceneID: `6_aco`,
    coords: {
      yaw: 1.6966122030142206,
      pitch: 0.21866388910039447,
    },
  },
  {
    id: 6,
    label: `Protivpovratne klapne`,
    image: m6,
    sceneID: `4_aco`,
    coords: {
      yaw: -1.2746076616217827,
      pitch: 0.3457684144135129,
    },
  },
  {
    id: 7,
    label: `Odvodnjavanje u visokogradnji 1`,
    image: m7,
    sceneID: `4_aco`,
    coords: {
      yaw: -2.1974480281682798,
      pitch: 0.3209855034368587,
    },
  },
  {
    id: 8,
    label: `Odvodnjavanje u visokogradnji 2`,
    image: m8,
    sceneID: `4_aco`,
    coords: {
      yaw: 1.4114561377962698,
      pitch: 0.3040718360856651,
    },
  },
  {
    id: 9,
    label: `Separator masti`,
    image: m9,
    sceneID: `8_aco`,
    coords: {
      yaw: 1.6085906817975033,
      pitch: 0.1745446195133784,
    },
  },
  {
    id: 10,
    label: `ACO i umetnost`,
    image: m10,
    sceneID: `1_aco`,
    coords: {
      yaw: -0.7127817465522988,
      pitch: -0.08095666107995925,
    },
  },
  {
    id: 11,
    label: `ACO filozofija`,
    image: m11,
    sceneID: `3_aco`,
    coords: {
      yaw: 0.18230258182462755,
      pitch: -0.00913122821482304,
    },
  },
];

export type MenuList = typeof menuList;
