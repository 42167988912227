import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Linkedin: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        aria-hidden="true"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
        {...props}
      >
        <path d="M22.3 0H1.7C.8 0 0 .8 0 1.7v20.5c0 1 .8 1.7 1.7 1.7h20.6c.9 0 1.7-.8 1.7-1.7V1.7c0-.9-.8-1.7-1.7-1.7zm-15 20.6H3.7V9.1h3.6v11.5zm-1.8-13c-1.1 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1c1.1 0 2.1.9 2.1 2.1-.1 1.1-1 2.1-2.1 2.1zm15.1 13H17V15c0-1.3 0-3-1.8-3-1.9 0-2.1 1.4-2.1 2.9v5.7H9.5V9.1h3.4v1.6c.5-.9 1.6-1.8 3.4-1.8 3.6 0 4.3 2.4 4.3 5.5v6.2z" />
      </svg>
    );
  }
);
