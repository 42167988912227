import { useCallback, useRef, useState } from 'react';

import { useUnmount } from './use-unmount';

export function useForceUpdate(): () => void {
  const unloadingRef = useRef(false);
  const [count, setCount] = useState(0);

  useUnmount(() => {
    unloadingRef.current = true;
  });

  return useCallback(() => {
    if (!unloadingRef.current) {
      setCount(count + 1);
    }
  }, [count]);
}
