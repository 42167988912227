import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Pinterest: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        aria-hidden="true"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
        {...props}
      >
        <path d="M24 2.6v18.9c0 1.4-1.2 2.6-2.6 2.6H8.3c.5-.9 1.2-2.1 1.5-3.2.2-.6.8-3.1.8-3.1.4.8 1.7 1.5 3 1.5 4 0 6.8-3.6 6.8-8.2 0-4.3-3.5-7.6-8.1-7.6-5.7 0-8.7 3.8-8.7 8 0 1.9 1 4.3 2.7 5.1.3.1.4.1.4-.2 0-.2.3-1.1.4-1.5 0-.1 0-.2-.1-.4-.5-.7-1-1.9-1-3 0-2.9 2.2-5.7 5.9-5.7 3.2 0 5.5 2.2 5.5 5.4 0 3.6-1.8 6-4.1 6-1.3 0-2.3-1.1-1.9-2.4.4-1.6 1.1-3.3 1.1-4.4 0-2.8-4-2.4-4 1.3 0 1.2.4 2 .4 2-1.8 7.1-2 7.1-1.7 10.3h.1-4.7C1.2 24 0 22.8 0 21.4V2.6C0 1.2 1.2 0 2.6 0h18.9C22.8 0 24 1.2 24 2.6z" />
      </svg>
    );
  }
);
