import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Facebook: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        aria-hidden="true"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
        {...props}
      >
        <path d="M21.4 0H2.6C1.2 0 0 1.2 0 2.6v18.9C0 22.8 1.2 24 2.6 24H10v-8.2H6.5V12h3.4V9.1c0-3.3 2-5.2 5-5.2 1.5 0 3 .3 3 .3v3.3h-1.7c-1.7 0-2.2 1-2.2 2.1V12h3.7l-.6 3.8H14V24h7.4c1.4 0 2.6-1.2 2.6-2.6V2.6C24 1.2 22.8 0 21.4 0z" />
      </svg>
    );
  }
);
