import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Pause: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
      >
        <g>
          <path d="M185.5 0H99.6C83.9 0 71.3 12.6 71.3 28.4v455.3c0 15.8 12.6 28.4 28.4 28.4h85.9c15.8 0 28.4-12.6 28.4-28.4V28.4C213.8 12.6 201.2 0 185.5 0z" />
          <path d="M413.1 0h-85.9c-15.8 0-28.4 12.6-28.4 28.4v455.3c0 15.8 12.6 28.4 28.4 28.4h85.1c15.8 0 28.4-12.6 28.4-28.4V28.4C441.5 12.6 428.1 0 413.1 0z" />
        </g>
      </svg>
    );
  }
);
