import React, { FC } from 'react';
import styled from 'styled-components';

import { Link } from './link';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5em;

  a {
    color: #000;
    transition: color 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const Address = styled.span`
  margin-right: 0.5em;
`;

const Phone = styled(Link)``;

const Separator = styled.span`
  margin: 0 0.5em;
`;

const Email = styled(Link)``;

const data = {
  contactLabel: `Kontaktirajte nas`,
  address: `III Industrijska zona bb, 22314 Krnješevci`,
  phone: `022/811 580`,
  email: `aco@aco.rs`,
};

type Props = {
  className?: string;
};

export const Info: FC<Props> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Address>{data.address}</Address>
      <div>
        <Phone to={`tel: ${data.phone}`}>{`t: ${data.phone}`}</Phone>
        <Separator>|</Separator>
        <Email to={`mailto: ${data.email}`}>{`e: ${data.email}`}</Email>
      </div>
    </Wrapper>
  );
};
