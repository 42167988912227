import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { __DEV__, createContext } from '~src/utils';

// Kada bude povezan CMS, ucitati tour data u Static Query na App Layout i tu apdejtovati context
import { tour } from '../data';

type MenuContext = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  menuState: {
    sceneID: string;
    coords: {
      pitch: number;
      yaw: number;
    };
  };
  setmMenuState: Dispatch<
    SetStateAction<{
      sceneID: string;
      coords: {
        pitch: number;
        yaw: number;
      };
    }>
  >;
};

const [Provider, useMenuContext] = createContext<MenuContext>({
  strict: true,
  name: 'MenuContext',
  errorMessage: 'useMenuContext must be inside a MenuProvider with a value`',
});

const initialMenuState = {
  sceneID: tour.scenes[0].id,
  coords: {
    pitch: tour.scenes[0].initialViewParameters.pitch,
    yaw: tour.scenes[0].initialViewParameters.yaw,
  },
};

const MenuProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuState, setmMenuState] = useState(initialMenuState);

  const context = {
    setIsOpen,
    isOpen,
    menuState,
    setmMenuState,
  };

  return <Provider value={context}>{children}</Provider>;
};

if (__DEV__) {
  MenuProvider.displayName = 'MenuProvider';
}

export { MenuProvider, useMenuContext };
