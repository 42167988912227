import React, { FC } from 'react';
import styled from 'styled-components';

import { SEO } from '~src/components/seo';
import { useMobileDetect, useWindowSize } from '~src/hooks';

const Wrapper = styled.div<{ noBorder: boolean; $height: string }>`
  padding: ${p => (p.noBorder ? 0 : `1.5em`)};
  height: ${p => p.$height};
  width: 100%;
`;

type Props = {
  pathname?: string;
  seoData: {
    title?: string;
    description?: string;
    featured_image?: {
      imageFile: {
        childImageSharp: {
          fixed: {
            src: string;
          };
        };
      };
    };
  };
  noBorder?: boolean;
};

const PageLayout: FC<Props> = ({
  children,
  seoData,
  pathname,
  noBorder = false,
}) => {
  const { height } = useWindowSize();

  const isMobile = useMobileDetect();

  const conditionalHeight = isMobile && height ? `${height}px` : `100vh`;

  return (
    <>
      <SEO seoData={seoData} pathname={pathname} />
      <Wrapper $height={conditionalHeight} noBorder={noBorder}>
        {children}
      </Wrapper>
    </>
  );
};

export default PageLayout;
