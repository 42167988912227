import { graphql, PageProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import React, { FC } from 'react';

import { Background } from '~src/components/background';
import { TopLogo } from '~src/components/top-logo';
import { WelcomeSlide } from '~src/components/welcome-slide';
import { useMenuContext } from '~src/context/menu-context';
import { useMedia, useUpdateEffect } from '~src/hooks';
import Layout from '~src/layouts/page-layout';

type Data = {
  coverImage: {
    childImageSharp: {
      fluid: FluidObject | FluidObject[];
    };
  };
};

const seoData = {
  title: 'Dobro došli',
};

const WelcomePage: FC<PageProps<Data>> = ({ location, data }) => {
  const { isOpen } = useMenuContext();

  const isLandscape = useMedia(`(orientation: landscape)`);

  // Problem with IOS not capturing width and hight after orientation change
  useUpdateEffect(() => {
    window.location.reload(false);
  }, [isLandscape]);

  return (
    <Layout seoData={seoData} pathname={location.pathname}>
      <TopLogo small={isOpen} />
      <Background fluid={data.coverImage.childImageSharp.fluid}>
        <WelcomeSlide />
      </Background>
    </Layout>
  );
};

export default WelcomePage;

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "welcome.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1900, maxHeight: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
