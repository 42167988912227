import { HotspotsQueryData } from '~src/types/strapi-types';

export const tour = {
  scenes: [
    {
      id: '1_aco',
      name: '1_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.24427041932012017,
        yaw: 2.45585652536621,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: 0.5555218249011258,
          pitch: 0.30574438608239163,
          rotation: -15,
          size: 120,
          angle: 60,
          target: '2_aco',
        },
        {
          yaw: 0.24957747371842665,
          pitch: 0.3278688960763265,
          rotation: -40,
          size: 120,
          angle: 60,
          target: '3_aco',
        },
        {
          yaw: -1.2309318115093735,
          pitch: 0.1983023905204213,
          rotation: -6,
          size: 120,
          angle: 60,
          target: '7_aco',
        },
      ],
    },
    {
      id: '2_aco',
      name: '2_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.21589897505189626,
        yaw: -2.7951535719123157,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: -0.4519848380941234,
          pitch: 0.36482821865836,
          rotation: 0,
          size: 120,
          angle: 60,
          target: '1_aco',
        },
        {
          yaw: 1.030252853011529,
          pitch: 0.3225872302947117,
          rotation: 0,
          size: 120,
          angle: 60,
          target: '3_aco',
        },
      ],
    },
    {
      id: '3_aco',
      name: '3_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.023889039461122863,
        yaw: -3.0441614342315617,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: 1.5899549063709468,
          pitch: 0.3319629062182514,
          rotation: 0,
          size: 120,
          angle: 60,
          target: '2_aco',
        },
        {
          yaw: -1.5929696814369017,
          pitch: 0.38635417414283246,
          rotation: 0,
          size: 120,
          angle: 60,
          target: '4_aco',
        },
        {
          yaw: -0.8329353078385431,
          pitch: 0.33806638434891667,
          rotation: 10,
          size: 120,
          angle: 60,
          target: '5_aco',
        },
        {
          yaw: -2.1614040414984537,
          pitch: 0.4282322355294301,
          rotation: -10,
          size: 120,
          angle: 60,
          target: '6_aco',
        },
      ],
    },
    {
      id: '4_aco',
      name: '4_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.18858879490449887,
        yaw: -1.6829052659061556,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: -0.022773917814005173,
          pitch: 0.2790746555665855,
          rotation: -3,
          size: 120,
          angle: 60,
          target: '3_aco',
        },
        {
          yaw: -1.672574493002731,
          pitch: 0.5661220870032313,
          rotation: 0,
          size: 120,
          angle: 60,
          target: '5_aco',
        },
        {
          yaw: 2.246706555614278,
          pitch: 0.46982974534555133,
          rotation: -40,
          size: 120,
          angle: 60,
          target: '6_aco',
        },
      ],
    },
    {
      id: '5_aco',
      name: '5_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.4271571977099473,
        yaw: -1.5694354286571812,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: -1.553564669601295,
          pitch: 0.7035022356017748,
          rotation: 0,
          size: 150,
          angle: 60,
          target: '4_aco',
        },
        {
          yaw: -2.7286179865611366,
          pitch: 0.5088989929188408,
          rotation: 20,
          size: 150,
          angle: 60,
          target: '3_aco',
        },
      ],
    },
    {
      id: '6_aco',
      name: '6_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.21866388910039447,
        yaw: 1.6966122030142206,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: 0.9913336769235563,
          pitch: 0.3006127462503638,
          rotation: -60,
          size: 120,
          angle: 60,
          target: '1_aco',
        },
        {
          yaw: 2.280817186865008,
          pitch: 0.2730844545878952,
          rotation: -45,
          size: 120,
          angle: 60,
          target: '8_aco',
        },
        {
          yaw: 2.4891684714846463,
          pitch: 0.4127484035394282,
          rotation: -85,
          size: 120,
          angle: 60,
          target: '7_aco',
        },
        {
          yaw: -2.6024337655011287,
          pitch: 0.4792541122114624,
          rotation: 67,
          size: 120,
          angle: 60,
          target: '4_aco',
        },
        {
          yaw: -0.4656238502742216,
          pitch: 0.5077381969671588,
          rotation: -10,
          size: 120,
          angle: 60,
          target: '3_aco',
        },
      ],
    },
    {
      id: '7_aco',
      name: '7_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.32988959352867653,
        yaw: -1.5284560886277578,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: 0.07264336174763564,
          pitch: 0.475250737449473,
          rotation: 0,
          size: 120,
          angle: 60,
          target: '1_aco',
        },
        {
          yaw: -2.8988698178737042,
          pitch: 0.46335442070650856,
          rotation: 80,
          size: 120,
          angle: 60,
          target: '6_aco',
        },
        {
          yaw: 2.6835516513146436,
          pitch: 0.42689728365597546,
          rotation: -65,
          size: 120,
          angle: 60,
          target: '8_aco',
        },
      ],
    },
    {
      id: '8_aco',
      name: '8_aco',
      levels: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
        {
          tileSize: 512,
          size: 8192,
        },
      ],
      faceSize: 5750,
      initialViewParameters: {
        pitch: 0.1745446195133784,
        yaw: 1.6085906817975033,
        fov: 1.5707963267948966,
      },
      linkHotspots: [
        {
          yaw: 0.390901335874549,
          pitch: 0.4367218222322329,
          rotation: 35,
          size: 120,
          angle: 60,
          target: '7_aco',
        },
        {
          yaw: 0.7224162684387192,
          pitch: 0.3520165895989411,
          rotation: 55,
          size: 120,
          angle: 60,
          target: '1_aco',
        },
      ],
    },
  ],
  name: 'ACO Tour',
  settings: {
    mouseViewMode: 'drag',
    autorotateEnabled: true,
    fullscreenButton: true,
    viewControlButtons: true,
  },
};

export type Tour = {
  scenes: {
    id: string;
    name: string;
    levels: (
      | {
          tileSize: number;
          size: number;
          fallbackOnly: boolean;
        }
      | {
          tileSize: number;
          size: number;
          fallbackOnly?: undefined;
        }
    )[];
    faceSize: number;
    initialViewParameters: {
      pitch: number;
      yaw: number;
      fov: number;
    };
    linkHotspots: {
      yaw: number;
      pitch: number;
      rotation: number;
      size: number;
      angle: number;
      target: string;
    }[];
    infoHotspots: HotspotsQueryData['strapi']['scenes'][number]['hotspots'];
  }[];
  name: string;
  settings: {
    mouseViewMode: string;
    autorotateEnabled: boolean;
    fullscreenButton: boolean;
    viewControlButtons: boolean;
  };
};
