import Loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, memo, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import { tour } from '~src/data';
import { useMedia, useWindowSize } from '~src/hooks';
import { HotspotsQueryData } from '~src/types/strapi-types';

import { FullScreen } from './controls/full-screen';

const LoadablePanoView = Loadable(() => import('./pano-view'));

export const hotspots = graphql`
  query Hotspots {
    strapi {
      scenes {
        id
        name
        hotspots {
          id
          yaw
          pitch
          name
          bloc {
            id
            title
            text
            images {
              id
              caption
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 356, maxHeight: 128, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            linkLearnMore: learn_more_url
            linkSimilarProduct: similar_product_url
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div<{ $height: string }>`
  position: relative;
  overflow: hidden;
  height: ${p => p.$height};
  width: 100%;
`;

type Props = {
  autorotateOn: boolean;
  hasGyroscope: boolean;
};

export const Pano: FC<Props> = memo(({ autorotateOn, hasGyroscope }) => {
  const {
    strapi: { scenes },
  } = useStaticQuery<HotspotsQueryData>(hotspots);

  const tourScenes = useMemo(
    () =>
      tour.scenes.map(scene => {
        const strapiScene = scenes?.find(({ name }) => name === scene.name);

        return {
          ...scene,
          infoHotspots: strapiScene ? strapiScene.hotspots : [],
        };
      }),
    [scenes]
  );

  const { height } = useWindowSize();

  const { breakpoints } = useTheme();
  const tablet = useMedia(
    `(max-width: ${breakpoints.tablet}px), (max-width: ${breakpoints.tabletLandscape}px) and (orientation: landscape)`
  );

  const conditionalHeight = tablet && height ? `${height}px` : `100%`;

  return (
    <Wrapper $height={conditionalHeight}>
      <FullScreen>
        <LoadablePanoView
          scenes={tourScenes}
          autorotateOn={autorotateOn}
          hasGyroscope={hasGyroscope}
        />
      </FullScreen>
    </Wrapper>
  );
});
