import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch';

const httpLink = new HttpLink({
  fetch,
  uri: process.env.GATSBY_API_URL_GRAPHQL,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache();

/* create apollo client */
export const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const links = [errorLink, httpLink];

  const link = ApolloLink.from(links);

  const client = new ApolloClient({
    link,
    cache,
  });

  return client;
};
