import { css } from 'styled-components';

export const buttonMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 3em;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease-out;

  > svg {
    height: 1.5em;
    width: 1.5em;
    fill: #ddd;
  }

  ${p => p.theme.minWidth.tabletLandscape`
    &:hover {
      background-color: #222 !important;
      > svg {
        fill: #fff;
      }
    }
  `}
  ${p => p.theme.maxWidth.tablet`
    height: 4em;
    width: 4em;

    > svg {
      height: 2.5em;
      width: 2.5em;
    }
  `}
`;
