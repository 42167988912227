import React, { FC } from 'react';
import styled from 'styled-components';

import { Link } from '~src/components/link';
import { LogoSVG } from '~src/components/logo-svg';

const Wrapper = styled(Link)<{ $small: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 12.75em;
  height: 11.25em;
  z-index: ${p => p.theme.zIndexes.popover};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${p => (p.$small ? `0.45em` : `1em`)};
  transition: all 0.3s ease-in-out;

  > svg {
    width: 6em;
    height: 4.625em;
  }
`;

type Props = {
  small?: boolean;
};

export const TopLogo: FC<Props> = ({ small = false }) => (
  <Wrapper to="/" $small={small}>
    <LogoSVG />
  </Wrapper>
);
