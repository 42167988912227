export const colors = {
  // Primary Colors
  white: `#fff`,
  black: `#000`,
  red: `#c00`,
  gray1: `#333`,
  gray2: `#666`,
  gray3: `#999`,
  gray4: `#ccc`,
  gray5: `#e6e6e6`,
  // Secondary Colors
  blueDark: `#1e3e5e`,
  blueMid: `#84abc1`,
  blueLight: `#bccfdf`,
  redDark: `#940000`,
  grayMid: `#bfbfbf`,
};
