import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const LinkArrowRight: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        width={16.641}
        height={10.576}
        viewBox="0 0 16.641 10.576"
        className={className}
        {...props}
      >
        <g
          data-name="lar-group-11"
          transform="translate(-1390.5 -677.313)"
          fill="none"
          stroke="#000"
          strokeWidth={1}
        >
          <path
            data-name="lar-line-3"
            transform="translate(1390.5 682.5)"
            d="M0 0L16 0"
          />
          <path
            data-name="lar-path-7"
            d="M1401.5 677.667l4.934 4.934-4.934 4.934"
          />
        </g>
      </svg>
    );
  }
);
