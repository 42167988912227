import React, { FC, memo, useCallback } from 'react';
import {
  FullScreen as ReactFullScreen,
  useFullScreenHandle,
} from 'react-full-screen';
import styled from 'styled-components';

import { __DEV__, createContext } from '~src/utils';

import { FullScreenEnter, FullScreenExit } from '../icons';
import { buttonMixin } from './mixin';

type FullScreenContextValue = {
  toggleFullScreen: () => void;
  isFullScreen: boolean;
};

const [
  FullScreenContextProvider,
  useFullScreenContext,
] = createContext<FullScreenContextValue>({
  strict: true,
  name: 'FullScreenContext',
  errorMessage:
    'useFullScreenContext: `context` is undefined. Seems you forgot to wrap components in `<FullScreen />`',
});

export { FullScreenContextProvider, useFullScreenContext };

export const FullScreen: FC = ({ children }) => {
  const handle = useFullScreenHandle();

  const toggleFullScreen = useCallback(() => {
    if (!handle.active) handle.enter();
    if (handle.active) handle.exit();
  }, [handle]);

  const context = {
    toggleFullScreen,
    isFullScreen: handle.active,
  };

  return (
    <FullScreenContextProvider value={context}>
      <ReactFullScreen handle={handle}>{children}</ReactFullScreen>
    </FullScreenContextProvider>
  );
};

if (__DEV__) {
  FullScreen.displayName = 'FullScreen';
}

const Button = styled.button`
  ${buttonMixin}
  margin-right: 1px;
  margin-left: 1px;
`;

type FullScreenToggleProps = {
  className?: string;
};

export const FullScreenToggle: FC<FullScreenToggleProps> = memo(
  ({ className = '' }) => {
    const { toggleFullScreen, isFullScreen } = useFullScreenContext();

    return (
      <Button onClick={toggleFullScreen} className={className}>
        {!isFullScreen ? <FullScreenEnter /> : <FullScreenExit />}
      </Button>
    );
  }
);

if (__DEV__) {
  FullScreenToggle.displayName = 'FullScreenToggle';
}
