import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const ThreeSixtySVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 73.467 73.467"
        className={className}
        {...props}
      >
        <g data-name="360-degrees" id="threeSixtyContainer" opacity={0.3}>
          <path
            data-name="Path 13"
            d="M2.376 0A2.376 2.376 0 110 2.376 2.376 2.376 0 012.376 0z"
            transform="translate(48.208 27.567)"
            fill="none"
          />
          <g data-name="Group 13" id="threeSixtyShape" fill="#fff">
            <path
              data-name="Path 8"
              d="M55.338 18.2a42.193 42.193 0 00-2.1-5.092C49.159 4.821 43.116 0 36.733 0 24.851 0 15.209 16.5 15.209 36.733a58.338 58.338 0 002.173 16.092C8.148 49.363 2.1 43.455 2.1 36.733c0-5.907 4.753-11.407 13.1-15.141a1.034 1.034 0 10-.815-1.9C5.228 23.765 0 29.943 0 36.733c0 7.876 7.333 14.8 18.2 18.536a43.521 43.521 0 003.191 7.2c4.074 7.061 9.506 11 15.345 11 6.79 0 13.037-5.228 17.111-14.462a1.034 1.034 0 10-1.9-.815c-3.734 8.352-9.3 13.172-15.209 13.172-5.092 0-9.913-3.531-13.58-9.913a39.066 39.066 0 01-2.512-5.364 60.254 60.254 0 0016.092 2.173c20.234 0 36.733-9.642 36.733-21.524-.004-7.879-7.337-14.805-18.133-18.536zm-18.6 37.956a57.423 57.423 0 01-16.907-2.444A56.2 56.2 0 0117.314 36.8c0-19.08 8.691-34.628 19.419-34.628 5.568 0 10.932 4.346 14.6 11.95.543 1.086.951 2.173 1.426 3.327a59.742 59.742 0 00-16.024-2.173 62.5 62.5 0 00-14.462 1.7 1.054 1.054 0 00.543 2.037A58.477 58.477 0 0136.8 17.382a57.423 57.423 0 0116.907 2.444 44.473 44.473 0 011.222 4.549A57.133 57.133 0 0156.22 36.8a57.754 57.754 0 01-1.63 13.851 1.054 1.054 0 002.037.543 61.771 61.771 0 001.7-14.327 60.924 60.924 0 00-1.358-12.833l-.815-3.259c9.166 3.327 15.209 9.234 15.209 15.956-.001 10.73-15.55 19.421-34.63 19.421z"
            />
            <path
              data-name="Path 9"
              d="M127.168 200.3a2.813 2.813 0 002.1-2.648c0-1.562-1.358-2.852-3.8-2.852a6.7 6.7 0 00-3.531.883l.543 1.9a5.2 5.2 0 012.376-.679c1.086 0 1.7.475 1.7 1.154 0 .951-1.154 1.29-2.037 1.29H123.5v1.9h1.086c1.154 0 2.309.475 2.309 1.63 0 .883-.679 1.494-2.1 1.494a7.047 7.047 0 01-2.648-.679l-.543 1.969a6.89 6.89 0 003.395.815c2.852 0 4.617-1.494 4.617-3.463a2.772 2.772 0 00-2.448-2.714z"
              transform="translate(-100.959 -161.733)"
            />
            <path
              data-name="Path 10"
              d="M183.228 198.738a3.173 3.173 0 00-2.376.883h-.068c.272-1.358 1.358-2.512 3.734-2.716a5.862 5.862 0 011.018-.068V194.8h-1.086a6.935 6.935 0 00-4.413 1.7 6.836 6.836 0 00-2.037 5.022c0 2.716 1.494 4.957 4.481 4.957a4.075 4.075 0 004.21-4.074 3.408 3.408 0 00-3.463-3.667zm-.747 5.839c-1.222 0-1.765-1.086-1.833-2.309a1.461 1.461 0 01.136-.679 1.837 1.837 0 011.562-1.018c1.154 0 1.7.883 1.7 1.969-.003 1.223-.614 2.037-1.565 2.037z"
              transform="translate(-147.785 -161.733)"
            />
            <path
              data-name="Path 11"
              d="M239.546 194.8c-2.92 0-4.346 2.58-4.346 5.907 0 3.191 1.29 5.839 4.21 5.839s4.346-2.376 4.346-5.907c-.001-3.123-1.156-5.839-4.21-5.839zm0 9.71c-1.018 0-1.63-1.222-1.63-3.87s.679-3.87 1.63-3.87c1.086 0 1.63 1.358 1.63 3.87q-.103 3.87-1.63 3.87z"
              transform="translate(-195.275 -161.733)"
            />
            <path
              data-name="Path 12"
              d="M279.07 154a3.87 3.87 0 103.87 3.87 3.826 3.826 0 00-3.87-3.87zm0 5.568a1.765 1.765 0 111.765-1.765 1.713 1.713 0 01-1.765 1.765z"
              transform="translate(-228.486 -127.859)"
            />
          </g>
        </g>
      </svg>
    );
  }
);
