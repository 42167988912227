import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const FullScreenEnter: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
      >
        <path d="M72.7 328.7H0V512h183.3v-72.7H72.7V328.7zM0 183.3h72.7V72.7h109.6V0H0v183.3zm439.3 256H328.7V512H512V328.7h-72.7v110.6zM328.7 0v72.7h109.6v109.6H512V0H328.7z" />
      </svg>
    );
  }
);
