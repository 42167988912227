import { navigate } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Info } from '~src/components/info';
import { Link } from '~src/components/link';
import { Social } from '~src/components/social';
import { TopLogo } from '~src/components/top-logo';
import Layout from '~src/layouts/page-layout';

const Wrapper = styled.div`
  color: #000;
  background-color: #fff;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  ${p => p.theme.maxWidth.tablet`
    justify-content: center;
  `};
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50em;
  height: 20em;
  ${p => p.theme.maxWidth.tablet`
    font-size: 0.8em;
  `};
  ${p => p.theme.maxWidth.phone`
    font-size: 0.6em;
  `};
  ${p => p.theme.maxWidth.small`
    font-size: 0.5em;
  `};
`;

const H1 = styled.h1`
  display: inline-block;
  font-size: 14.75em;
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 1px #000;
  margin-bottom: 0;
`;

const H2 = styled.h2`
  font-size: 3em;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.4;
  text-align: left;
  color: #000;
  width: 4.6em;
  height: 4em;
  margin: 0;
`;

const Separator = styled.div`
  width: 1px;
  height: 13.25em;
  background-color: ${p => p.theme.colors.red};
`;

const BackLink = styled(Link)`
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.3;
  text-transform: uppercase;
  color: #000;
  padding: 0.75em 2em;
  border: solid 0.5px #000;
  text-align: center;
  width: fit-content;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #000;
  }

  ${p => p.theme.maxWidth.tablet`
    font-size: 1.3em;
  `}
  ${p => p.theme.maxWidth.phone`
    margin-top: 1em;
  `}
`;

const Footer = styled.div`
  padding: 1rem 0;
  margin-top: auto;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-top: 2em;
  border-top: 1px solid #000;
  ${p => p.theme.maxWidth.phone`
    flex-direction: column;
  `}
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  ${p => p.theme.maxWidth.tablet`
    flex-direction: column;
  `};
  ${p => p.theme.maxWidth.phone`
    margin-bottom: 2em;
  `}
`;

const Contact = styled.button`
  font-size: 1em;
  font-weight: 600;
  text-transform: initial;
  color: #000;
  transition: color 0.2s ease-in-out;
  margin-right: 1.5em;
  ${p => p.theme.maxWidth.tablet`
    margin-right: auto;
    margin-bottom: 1em;
  `};
  ${p => p.theme.maxWidth.phone`
    margin-right: 0;
    margin-bottom: 1em;
  `}

  &:hover {
    opacity: 0.6;
  }
`;

const FooterInfo = styled(Info)`
  margin-top: 0;

  > span,
  > div {
    ${p => p.theme.maxWidth.phone`
      width: 100%;
    `}
  }
`;

const data = {
  contactLabel: `Kontaktirajte nas`,
};

const seoData = {
  title: '404: Not found',
};

const NotFoundPage: FC = () => (
  <Layout seoData={seoData}>
    <TopLogo small />
    <Wrapper>
      <Content>
        <Main>
          <H1>404</H1>
          <Separator />
          <H2>Page not found</H2>
        </Main>
        <BackLink to="/">Nazad na početnu</BackLink>
      </Content>
      <Footer>
        <FooterContent>
          <FooterLeft>
            <Contact
              onClick={() => {
                navigate('/dobro-dosli/');
              }}
            >
              {data.contactLabel}
            </Contact>
            <FooterInfo />
          </FooterLeft>
          <Social />
        </FooterContent>
      </Footer>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
