import React, { FC, ReactNode, useRef } from 'react';

import { useForceUpdate, useIsomorphicLayoutEffect } from '~src/hooks';
import { __DEV__, createContext } from '~src/utils';

interface PortalManagerContext {
  node: HTMLElement;
  zIndex?: number;
}

const [
  PortalManagerContextProvider,
  usePortalManager,
] = createContext<PortalManagerContext>({
  strict: false,
  name: 'PortalManagerContext',
});

export { usePortalManager };

export interface PortalManagerProps {
  /**
   * Child elements of the Portal manager
   * Ideally, it should be at the top-level
   * of your application
   */
  children?: ReactNode;
  /**
   * [Z-Index war] If your has multiple elements
   * with z-index clashing, you might need to
   * apply a z-index to the Portal manager
   */
  zIndex?: number;
}

/**
 * PortalManager
 *
 * Used to manage multiple portals within an application.
 * It must be render only once, at the root of your application.
 *
 * Inspired by BaseWeb's LayerManager component
 */
export const PortalManager: FC<PortalManagerProps> = props => {
  const { children, zIndex } = props;

  /**
   * The element that wraps the stacked layers
   */
  const ref = useRef<HTMLDivElement>(null);

  const forceUpdate = useForceUpdate();

  /**
   * force an update on mount so the Provider works correctly
   */
  useIsomorphicLayoutEffect(() => {
    forceUpdate();
  }, []);

  /**
   * let's detect if use has mutiple instances of this component
   */
  const parentManager = usePortalManager();

  const context = {
    node: parentManager?.node || ref.current,
    zIndex,
  };

  return (
    <PortalManagerContextProvider value={context}>
      {children}
      <div className="portal-manager" ref={ref} />
    </PortalManagerContextProvider>
  );
};

if (__DEV__) {
  PortalManager.displayName = 'PortalManager';
}
