import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import i18n from '~config/i18n';
import { useSiteMetadata } from '~src/hooks';

type Props = {
  lang?: keyof typeof i18n;
  pathname?: string;
  seoData: {
    title?: string;
    description?: string;
    featured_image?: {
      imageFile: {
        childImageSharp: {
          fixed: {
            src: string;
          };
        };
      };
    };
  };
  children?: ReactNode;
};

export const SEO: FC<Props> = ({
  lang = 'en',
  pathname = '',
  children = null,
  seoData,
}) => {
  const {
    siteTitle,
    siteTitleAlt: defaultTitle,
    siteUrl,
    siteDescription: defaultDescription,
    siteBanner,
  } = useSiteMetadata();

  const { htmlLang, ogLang } = i18n[lang];

  const seo = {
    title: seoData.title || defaultTitle,
    description: seoData.description || defaultDescription,
    banner: seoData.featured_image
      ? `${siteUrl}${seoData.featured_image.imageFile.childImageSharp.fixed.src}`
      : `${siteUrl}${siteBanner}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      title={seo.title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={htmlLang} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.banner} />
      <meta property="og:locale" content={ogLang} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.banner} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.banner} />
      <meta name="twitter:image:alt" content={seo.description} />
      {children}
    </Helmet>
  );
};
