import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Instagram: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        aria-hidden="true"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
        {...props}
      >
        <path d="M12 9.1c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9c1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9zm6.7-2.2c-.3-.7-.9-1.3-1.6-1.6-1.2-.4-3.9-.3-5.1-.3s-3.9-.1-5.1.3c-.7.3-1.3.9-1.6 1.6C4.9 8.1 5 10.8 5 12s-.1 3.9.3 5.1c.3.7.9 1.3 1.6 1.6 1.2.4 3.9.3 5.1.3s3.9.1 5.1-.3c.7-.3 1.3-.9 1.6-1.6.4-1.1.3-3.8.3-5.1s.1-3.9-.3-5.1zM12 16.4c-2.4 0-4.4-2-4.4-4.4s2-4.4 4.4-4.4 4.4 2 4.4 4.4c0 2.4-2 4.4-4.4 4.4zm4.6-8c-.6 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .5 1 1 0 .6-.5 1-1 1 0 .1 0 .1 0 0zM21.4 0H2.6C1.2 0 0 1.2 0 2.6v18.9C0 22.8 1.2 24 2.6 24h18.9c1.4 0 2.6-1.2 2.6-2.6V2.6C24 1.2 22.8 0 21.4 0zm-.9 15.5c-.1 1.4-.4 2.6-1.4 3.6-1 1-2.2 1.3-3.6 1.4-1.4.1-5.7.1-7.1 0-1.4-.1-2.6-.4-3.6-1.4s-1.3-2.2-1.4-3.6c-.1-1.4-.1-5.7 0-7.1.1-1.4.4-2.6 1.4-3.6S7 3.5 8.4 3.4c1.4-.1 5.7-.1 7.1 0 1.4.1 2.6.4 3.6 1.4s1.3 2.2 1.4 3.6c.1 1.5.1 5.7 0 7.1z" />
      </svg>
    );
  }
);
