import { useEffect } from 'react';

import { AnyFunction, BasicTarget, getTargetElement } from '~src/utils';

import { useLatestRef } from './use-latest-ref';

export type Target = BasicTarget<HTMLElement | Element | Window | Document>;

/**
 * React hook to manage browser event listeners
 *
 * @param eventName the event name
 * @param handler the event handler function to execute
 * @param target the dom environment to execute against
 * @param options the event listener options
 */
export function useEventListener(
  eventName: keyof WindowEventMap,
  handler: AnyFunction,
  target?: Target,
  options?: boolean | AddEventListenerOptions
): void {
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  const savedHandler = useLatestRef(handler);

  useEffect(() => {
    // Define the listening target
    const targetElement = getTargetElement(target, window);
    // Make sure element supports addEventListener
    if (!targetElement?.addEventListener) {
      return undefined;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (
      event: Event
    ): EventListenerOrEventListenerObject | AddEventListenerOptions => {
      return savedHandler.current?.(event);
    };

    // Add event listener
    targetElement.addEventListener(eventName, eventListener, options);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, options, savedHandler, target]);
}

//? DEMO
// import { useState, useRef } from 'react';
// import { useEventListener } from '~lib/hooks';

// export default () => {
//   const [value, setValue] = useState(0);

//   const clickHandler = () => {
//     setValue(value + 1);
//   };

//   const ref = useRef();
//   useEventListener('click', clickHandler, ref );

//   return (
//     <button ref={ref} type="button">
//       You click {value} times
//     </button>
//   );
// };

// export default () => {
//   const [value, setValue] = useState('');

//   const keyDownHandler = (ev: KeyboardEvent) => {
//     setValue(ev.code);
//   };
//   useEventListener('keydown', keyDownHandler);

//   return <p>Your press key is {value}</p>;
// };
