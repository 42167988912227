/* eslint-disable jsx-a11y/no-autofocus */
import React, { FC, useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { useMenuContext } from '~src/context/menu-context';
import { useDisclosure, useMedia, useTimeout } from '~src/hooks';
import { buttonMixin } from '~src/styles/mixin';

import { Link } from './link';
import { Menu } from './menu';
import { Drawer, DrawerContent } from './modal';

const Content = styled(DrawerContent)`
  align-items: center;
  justify-content: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GalleryLink = styled(Link)`
  ${buttonMixin}
  margin-bottom: 1.375em;
`;

const ProductMenu = styled.button`
  ${buttonMixin}
`;

const copy = {
  galleryLink: `Započnite obilazak`,
  menuBtn: `Naša rešenja`,
};

export const WelcomeSlide: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menu = useDisclosure();

  useTimeout(onOpen, 1000);

  const { setIsOpen } = useMenuContext();

  const openMenuOnClick = useCallback(() => {
    onClose();
    menu.onOpen();
  }, [menu, onClose]);

  const closeMenuOnClick = useCallback(() => {
    onOpen();
    menu.onClose();
  }, [menu, onOpen]);

  useEffect(() => {
    setIsOpen(menu.isOpen);
  }, [menu.isOpen, setIsOpen]);

  const { breakpoints } = useTheme();
  const desktop = useMedia(`(max-width: ${breakpoints.desktop}px)`);
  const phone = useMedia(`(max-width: ${breakpoints.phone}px)`);

  return (
    <>
      <Drawer
        onClose={menu.onOpen}
        isOpen={isOpen}
        placement={phone ? 'bottom' : 'right'}
        autoFocus={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size={desktop ? 45 : 30}
      >
        <Content>
          <Buttons>
            <GalleryLink to="izlozbeni-prostor">{copy.galleryLink}</GalleryLink>
            <ProductMenu onClick={openMenuOnClick}>{copy.menuBtn}</ProductMenu>
          </Buttons>
        </Content>
      </Drawer>
      <Menu onCloseMenu={closeMenuOnClick} isOpenMenu={menu.isOpen} />
    </>
  );
};
