import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * React effect hook that invokes only on update.
 * It doesn't invoke on mount
 */
export function useUpdateEffect(
  effect: EffectCallback,
  deps?: DependencyList
): void {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      return effect();
    }
    mounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
