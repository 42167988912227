import { colors } from './colors';
import { fonts } from './fonts';
import { breakpoints, maxWidth, minWidth } from './responsive';
import { zIndexes } from './zIndexes';

export const theme = {
  fonts,
  colors,
  zIndexes,
  minWidth,
  maxWidth,
  breakpoints,
  headerHeight: `5em`, // 124px
  headerPhoneHeight: `5em`,
  containerWidth: `85em`, // 1360px
  cardsRadius: `1.25em`,
};
