import { win } from './dom';

export function hasDisplayNone(element: HTMLElement): boolean {
  return win?.getComputedStyle(element).display === 'none';
}

export function hasTabIndex(element: HTMLElement): boolean {
  return element.hasAttribute('tabindex');
}

export function hasNegativeTabIndex(element: HTMLElement): boolean {
  return hasTabIndex(element) && element.tabIndex === -1;
}

export function isDisabled(element: HTMLElement): boolean {
  return (
    Boolean(element.getAttribute('disabled')) === true ||
    Boolean(element.getAttribute('aria-disabled')) === true
  );
}

export function hasFocusWithin(element: HTMLElement): boolean {
  if (!document.activeElement) return false;
  return element.contains(document.activeElement);
}

export function isHTMLElement(element: Element): element is HTMLElement {
  return element instanceof HTMLElement;
}

export function isHidden(element: HTMLElement): boolean {
  if (element.parentElement && isHidden(element.parentElement)) return true;
  return element.hidden;
}

export function isContentEditable(element: HTMLElement): boolean {
  const value = element.getAttribute('contenteditable');
  return value !== 'false' && value != null;
}

export function isFocusable(element: HTMLElement): boolean {
  if (!isHTMLElement(element) || isHidden(element) || isDisabled(element)) {
    return false;
  }

  const { localName } = element;
  const focusableTags = ['input', 'select', 'textarea', 'button'];

  if (focusableTags.indexOf(localName) >= 0) return true;

  const others = {
    a: () => element.hasAttribute('href'),
    audio: () => element.hasAttribute('controls'),
    video: () => element.hasAttribute('controls'),
  };

  if (localName in others) {
    return others[localName as keyof typeof others]();
  }

  if (isContentEditable(element)) return true;

  return hasTabIndex(element);
}

export function isTabbable(element?: HTMLElement | null): boolean {
  if (!element) return false;
  return (
    isHTMLElement(element) &&
    isFocusable(element) &&
    !hasNegativeTabIndex(element)
  );
}

export function isActiveElement(element: HTMLElement): boolean {
  return document.activeElement === element;
}

export function isInputElement(
  element: HTMLElement
): element is HTMLInputElement {
  return (
    isHTMLElement(element) &&
    element.tagName.toLowerCase() === 'input' &&
    'select' in element
  );
}

export interface FocusableElement {
  focus(options?: FocusOptions): void;
}

interface FocusProps extends FocusOptions {
  isActive?: typeof isActiveElement;
}

export function focus(element: HTMLElement, options: FocusProps = {}): number {
  const { isActive = isActiveElement, preventScroll } = options;

  if (isActive(element)) return -1;

  return requestAnimationFrame(() => {
    element.focus({ preventScroll });

    if (isInputElement(element)) {
      element.select();
    }
  });
}
