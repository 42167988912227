import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const FullScreenExit: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
      >
        <path d="M0 402.3h109.7V512h73.1V329.1H0v73.2zm109.7-292.6H0v73.1h182.9V0h-73.1v109.7zM329.1 512h73.1V402.3H512v-73.1H329.1V512zm73.2-402.3V0h-73.1v182.9H512v-73.1H402.3z" />
      </svg>
    );
  }
);
