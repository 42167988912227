import React, { FC } from 'react';
import styled from 'styled-components';

import { Pano } from './pano';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Widget: FC = () => {
  return (
    <Wrapper>
      <Pano autorotateOn={false} hasGyroscope />
    </Wrapper>
  );
};
