import { useEffect } from 'react';

import { useLatestRef } from './use-latest-ref';

/**
 * React hook that provides a declarative `setTimeout`
 *
 * @param callback the callback to run after specified delay
 * @param delay the delay (in ms)
 */
export function useTimeout(callback: () => void, delay: number | null): void {
  const savedCallback = useLatestRef(callback);

  useEffect(() => {
    if (delay == null) return undefined;

    let timeoutId: number | null = null;

    timeoutId = window.setTimeout(() => {
      savedCallback.current?.();
    }, delay);

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [delay, savedCallback]);
}
