import { PageProps } from 'gatsby';
import React, { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import { TopLogo } from '~src/components/top-logo';
import { Widget } from '~src/components/widget';
import { useIsomorphicLayoutEffect, useMedia } from '~src/hooks';
import Layout from '~src/layouts/page-layout';

const seoData = {
  title: 'Izložbeni prostor',
};

const Gallery: FC<PageProps> = ({ location }) => {
  const [noBorder, setNoBorder] = useState(false);

  const { breakpoints } = useTheme();
  const tablet = useMedia(
    `(max-width: ${breakpoints.tablet}px), (max-width: ${breakpoints.tabletLandscape}px) and (orientation: landscape)`
  );

  useIsomorphicLayoutEffect(() => {
    setNoBorder(tablet);
  }, [tablet]);

  return (
    <Layout seoData={seoData} pathname={location.pathname} noBorder={noBorder}>
      <TopLogo small />
      <Widget />
    </Layout>
  );
};

export default Gallery;
