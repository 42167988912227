const base = {
  name: `ACO Srbija i Crna Gora`, // Site title.
  shortName: `ACO Showroom`, // Site title.
  url: `https://acoshowroomtour.rs`, // Domain of your website. No trailing slash!
};

module.exports = {
  /* meta tags */
  siteTitle: `${base.name}`, // Manifest `name`
  siteTitleAlt: `${base.name}`, // Title for JSONLD.
  siteShortName: base.shortName, // Manifest `short_name`. MUST be shorter than 12 characters
  siteTitleTemplate: `%s | ${base.name}`, // Useful when you want titles to inherit from a template
  siteDescription: `Istražite izložbeni prostor ACO Srbija i Crna Gora, lidera u razvoju i proizvodnji rešenja u oblasti upravljanja površinskim i vodama unutar objekata.`, // Manifest `description`

  /* url */
  siteUrl: base.url, // Domain of your site. No trailing slash!
  pathPrefix: `/`, // Prefixes all links. For cases when deployed to example.github.io/gatsby-example/.

  /* social */
  siteBanner: `/featured-image.jpg`, // Logo used for SEO.
  facebook: ``, // Facebook Site Name

  googleAnalyticsID: `G-FXPBK68Z83`, // googleAnalyticsID gtag tracking ID.
  fbAppID: ``, // FB Application ID for using app insights

  /* manifest */
  manifestBackgroundColor: `#fff`, // Used for setting manifest background and progress theme colors.
  manifestThemeColor: `#1e3e5e`, // Used for setting manifest theme color .
  manifestDisplay: `standalone`,
  manifestIcon: `static/aco-icon.png`, // This path is relative to the root of the site.
};
