import { css } from 'styled-components';

const SCROLLBAR_WIDTH = 6;

export const customScrollbar = css`
  /* Chrome */
  &::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH}px;
    height: ${SCROLLBAR_WIDTH}px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${p => p.theme.colors.red};
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.colors.redDark};
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${p => p.theme.colors.red} transparent;
`;

export const buttonMixin = css`
  font-size: 1.125em;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.3;
  text-transform: uppercase;
  border: solid 0.5px ${p => p.theme.colors.red};
  text-align: center;
  width: 100%;
  padding: 0.75em;
  transition: all 0.2s ease-in-out;
  max-width: 18.75em;
  color: ${p => p.theme.colors.red};
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: ${p => p.theme.colors.red};
  }

  ${p => p.theme.maxWidth.tablet`
    font-size: 1.3em;
  `}
`;
