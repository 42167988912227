import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Play: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
      >
        <path d="M42.5 0L42.5 512 469.5 256z" />
      </svg>
    );
  }
);
