import { navigate } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Info } from '~src/components/info';
import { Link } from '~src/components/link';
import { Social } from '~src/components/social';
import { TopLogo } from '~src/components/top-logo';
import Layout from '~src/layouts/page-layout';

const Wrapper = styled.div`
  color: #000;
  background-color: #fff;
  height: auto;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3em;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 60em;
  padding: 5em 0;
  text-align: left;

  p {
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  ul {
    font-size: 1.2em;
  }

  a {
    user-select: text;
  }
`;

const H1 = styled.h1`
  font-size: 2em;
  color: ${p => p.theme.colors.red};
  text-transform: initial;
`;

const H2 = styled.h2`
  font-size: 1.75em;
  color: ${p => p.theme.colors.red};
  text-transform: initial;
  margin-bottom: 1em;
  margin-top: 1em;
`;

const H3 = styled.h3`
  font-size: 1.2em;
  color: ${p => p.theme.colors.red};
  text-transform: initial;
  margin: 0;
  display: inline;
`;

const ListItem = styled.div`
  display: flex;
  align-items: baseline;
  span {
    color: ${p => p.theme.colors.red};
  }
`;

const ListBull = styled.div`
  display: flex;
  margin-right: 1em;
`;

const ListTitle = styled.p`
  font-size: 1.2em;
  margin-bottom: 0.5em !important;
`;

const ListDash = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  margin-bottom: 1em;
`;

const ListDashItem = styled.li`
  &::before {
    display: inline-block;
    content: '-';
    width: 1em;
    margin-left: -1em;
  }
`;

const Footer = styled.div`
  padding: 1rem 0;
  margin-top: auto;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-top: 2em;
  border-top: 1px solid #000;
  ${p => p.theme.maxWidth.phone`
    flex-direction: column;
  `}
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  ${p => p.theme.maxWidth.tablet`
    flex-direction: column;
  `};
  ${p => p.theme.maxWidth.phone`
    margin-bottom: 2em;
  `}
`;

const Contact = styled.button`
  font-size: 1em;
  font-weight: 600;
  text-transform: initial;
  color: #000;
  transition: color 0.2s ease-in-out;
  margin-right: 1.5em;
  ${p => p.theme.maxWidth.tablet`
    margin-right: auto;
    margin-bottom: 1em;
  `};
  ${p => p.theme.maxWidth.phone`
    margin-right: 0;
    margin-bottom: 1em;
  `}

  &:hover {
    opacity: 0.6;
  }
`;

const BackLink = styled(Link)`
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.3;
  text-transform: uppercase;
  color: #000;
  padding: 0.75em 2em;
  border: solid 0.5px #000;
  text-align: center;
  width: fit-content;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #000;
  }

  ${p => p.theme.maxWidth.tablet`
    font-size: 1.3em;
  `}
`;

const FooterInfo = styled(Info)`
  margin-top: 0;

  > span,
  > div {
    ${p => p.theme.maxWidth.phone`
      width: 100%;
    `}
  }
`;

const data = {
  contactLabel: `Kontaktirajte nas`,
};

const seoData = {
  title: 'Politika privatnosti',
};

const PrivacyPage: FC = () => (
  <Layout seoData={seoData}>
    <TopLogo small />
    <Wrapper>
      <Content>
        <H1>Zaštita privatnosti i poverljivost podataka o ličnosti</H1>
        <p>
          Pravila zaštite ličnih podataka na internet stranici{' '}
          <Link to="www.aco.rs">www.aco.rs</Link> u skladu sa{' '}
          <strong>
            Zakonom o zaštiti podataka o ličnosti (‘’Sl glasnik RS’’, br.
            87/2018).
          </strong>
        </p>
        <H2>1. Opšte informacije</H2>
        <p>
          Ova Pravila zaštite privatnosti (dalje: Pravila) opisuju koje vaše
          podatke o ličnosti prikupljamo, na koji način ih obrađujemo, u koje
          svrhe ih upotrebljavamo, kao i Vaša prava povezana s Vašim podacima.
          <br />
          <br />
          <strong>
            ACO Građevinski elementi d.o.o., III Industrijska zona bb,
            Krnješevci, MB 17485911, PIB 102900059 (dalje: “ACO“ ili “mi“)
          </strong>{' '}
          obrađuje lične podatke korisnika svojih usluga i posetilaca stranice
          www.aco.rs u skladu s važećim propisima kojima je regulisana zaštita
          podataka o ličnosti (Zakon o zaštiti podataka o ličnosti ‘’Sl glasnik
          RS’’, br. 87/2018).
          <br />
          <br />
          Poštujemo Vašu privatnost i obrađujemo podatke o ličnosti pošteno,
          zakonito i transparentno, isključivo na osnovu važećih pravnih propisa
          i u skladu sa Vašim pristankom na obradu.
        </p>
        <H2>2. Način i svrhe obrade ličnih podataka</H2>
        <ListItem>
          <ListBull>&bull;</ListBull>
          <p>
            <H3>Skladištenje IP adrese.</H3> IP adresu koju je poslao Vaš veb
            pretraživač čuvamo tokom 7 (sedam) dana, strogo u svrhu
            identifikovanja, ograničavanja i uklanjanja napada na našu internet
            stranicu.
          </p>
        </ListItem>
        <p>
          Posle 7 (sedam) dana brišemo Vašu IP adresu. Pravni osnov za obradu
          ovih ličnih podataka predviđen je u čl. 12 st. 1. tačka 1. Zakona o
          zaštiti podataka o ličnosti.
        </p>
        <ListItem>
          <ListBull>&bull;</ListBull>
          <div>
            <p>
              <H3>Podaci o upotrebi.</H3> Kada posetite našu internet stranicu,
              podaci prikupljeni korišćenjem iste privremeno se čuvaju na našem
              web serveru, u statističke svrhe, a sve radi poboljšanja kvaliteta
              naše internet stranice.
            </p>
            <ListTitle>Ovaj skup podataka sadrži:</ListTitle>
            <ListDash>
              <ListDashItem>stranicu sa koje se zahtevaju podaci</ListDashItem>
              <ListDashItem>stranicu sa koje se zahtevaju podaci</ListDashItem>
              <ListDashItem>stranicu sa koje se zahtevaju podaci</ListDashItem>
              <ListDashItem>stranicu sa koje se zahtevaju podaci</ListDashItem>
              <ListDashItem>stranicu sa koje se zahtevaju podaci</ListDashItem>
              <ListDashItem>stranicu sa koje se zahtevaju podaci</ListDashItem>
            </ListDash>
          </div>
        </ListItem>
        <p>Skup podataka iz prethodnog pasusa se skladišti anonimno.</p>
        <ListItem>
          <ListBull>&bull;</ListBull>
          <p>
            <H3>Kontaktiranje.</H3> Ukoliko nam se obratite s vašim upitima o
            proizvodu i uslugama, podatke koje nam pri tome dostavite obrađujemo
            isključivo za pružanje traženih informacija. Nakon odgovora na vaš
            upit dostavljene podatke ćemo obrisati. Lični podaci koju su nužni
            za rešavanje reklamacije na proizvod čuvaju se 24 meseca od dana
            primanja u skladu sa propisima kojima se uređuju prava potrošača.
          </p>
        </ListItem>
        <ListItem>
          <ListBull>&bull;</ListBull>
          <p>
            <H3>Otvorene prijave za posao.</H3> Podatke koje nam pošaljete kroz
            otvorene prijave za posao obrađuju se isključivo u svrhe obavljanja
            selekcijskog postupka pri zapošljavanju, a čuvaju se dve godine.
          </p>
        </ListItem>
        <H2>3. Način i svrha korišćenja &lsquo;kolačića&rsquo;</H2>
        <p>
          Koristimo kolačiće na našem sajtu.
          <br />
          <br />
          Kolačiće koristimo kako bismo otkrili interese Vašeg korišćenja i
          posebno popularna područja naših internet stranica, te kako bismo
          pomoću tih informacija poboljšali dizajn internet stranice i učinili
          ih još jednostavnijima za korišćenje. U istu svrhu koristimo i
          analitičke alate Adobe Analytics i Google Analytics.
        </p>
        <H3>Funkcije i primena kolačića</H3>
        <br />
        <br />
        <p>
          Kolačići su male datoteke koje se sa internet stranice koju posetite
          spremaju čuvaju na Vašem krajnjem uređaju. Na osnovu toga možemo npr.
          prepoznati je li između Vašeg uređaja i naših internet stranica već
          postojala neka veza ili druge postavke koje preferirate. Kolačići mogu
          da sadrže i podatke o ličnosti.
          <br />
          <br />
          Postoji razlika između privremenih kolačića (session cookies), koji se
          brišu kada zatvorite svoj pretraživač, i trajnih kolačića koji se
          skladište i nakon što je vaša poseta sajtu završena. Kolačići mogu da
          sadrže podatke koji omogućavaju prepoznavanju uređaja koji se koristi.
          Ipak, u nekim slučajevima, kolačići sadrže samo informacije o
          određenim podešavanjima koji nisu podaci o ličnosti.
          <br />
          <br />
          Na svom sajtu koristimo privremene i trajne kolačiće. Podaci se
          obrađuju u skladu sa članom 12. Zakona o zaštiti podataka o ličnosti
          (‘’Zakonitost obrade’’), a u cilju optimizacije ili omogućavanja
          korsničkog iskustva i poboljšanja prisutnosti našeg web sajta.
          <br />
          <br />
          Ako koristite naše internet stranice, time pristajete na upotrebu
          kolačića.
        </p>
        <ListTitle>
          U svakom trenutku možete povući svoju saglasnost za obradu. Molimo vas
          da koristite jednu od sledećih opcija:
        </ListTitle>
        <ListDash>
          <ListDashItem>
            Možete da odbijete upotrebu kolačića odabirom odgovarajućih
            podešavanja u vašem pretraživaču, ali imajte na umu da ako nastavite
            u skladu sa tim možda nećete moći da iskoristite punu funkcionalnost
            ovog web sajta.
          </ListDashItem>
          <ListDashItem>
            Takođe možete da sprečite Google da prikuplja podatke koje generiše
            kolačić i koji se odnose na vašu upotrebu web sajta (uključujući
            vašu IP adresu) i da obrađuje ove podatke tako što ćete preuzeti i
            instalirati dodatak za pretraživač dostupan na sledećoj vezi{' '}
            <Link to="https://tools.google.com/dlpage/gaoptout?hl=en">
              (https://tools.google.com/dlpage/gaoptout?hl=en)
            </Link>{' '}
            .
          </ListDashItem>
        </ListDash>
        <H2>4. Prenos podataka drugim licima</H2>
        <p>
          ACO će Vaše lične podatke prosleđivati drugim licima isključivo na
          osnovu postojeće zakonske obaveze, odnosno opravdanog zahteva
          nadležnih državnih organa.
          <br />
          <br />
          Po potrebi podaci o ličnosti će se prosleđivati i pouzdanim licima
          (izvršiteljima obrade) u svrhe omogućavanja korisničke podrške,
          održavanja informacionog sistema, istraživanja tržišta ili sličnih
          potreba uz obavezne mere poverljivosti i zaštite podataka o ličnosti.
        </p>
        <H2>5. Zaštita podataka o ličnosti</H2>
        <p>
          Prikupljene podatke o ličnosti obrađujemo uz obaveznu primenu
          odgovarajućih pravnih, tehničkih, organizacionih i kadrovskih mera
          sigurnosti kako bismo sprečili svaku neovlašćenu obradu podataka na
          koji način se obezbeđuje integritet i poverljivost obrade.
          <br />
          <br />
          Pristup Vašim podacima o ličnosti ograničen je samo na one zaposlene
          kojima su te informacije nužne u pružanju naših usluga. Svakodnevno
          radimo na edukaciji naših zaposlenih o važnosti poverljivosti podataka
          i čuvanju privatnosti i zaštiti Vaših podataka. Prikupljamo samo one
          podatke koji su nužni za predmetnu obradu i ne čuvamo ih više nego što
          je potrebno, odnosno određeno zakonskim propisima.
        </p>
        <H2>6. Vaša prava kao korisnika</H2>
        <p>
          Kao korisnik internet stranice, Zakon o zaštiti podataka o ličnosti
          Vam daje određena prava prilikom obrade Vaših podataka o ličnosti.
        </p>
        <ListTitle>
          U svakom trenutku od nas možete zatražiti informaciju o obradi Vaših
          ličnih podataka i zatražiti ostvarivanje sledećih prava:
        </ListTitle>
        <ListDash>
          <ListDashItem>
            pravo na pristup podacima koji se odnose na Vas
          </ListDashItem>
          <ListDashItem>
            ispravku ili dopunu vaših podataka o ličnosti kako bi uvek bili
            tačni
          </ListDashItem>
          <ListDashItem>
            brisanje ličnih podataka (ukoliko ne postoje opravdani razlozi za
            čuvanje podataka)
          </ListDashItem>
          <ListDashItem>
            ograničenje obrade ličnih podataka, npr. kada prigovarate tačnosti
            podataka o ličnosti, a za vreme dok ne proverimo njihovu tačnost
          </ListDashItem>
          <ListDashItem>
            povlačenje saglasnosti za obradu podataka (npr. za primanje
            promotivnih poruka)
          </ListDashItem>
        </ListDash>
        <p>
          Ukoliko smatrate da je neko od Vaših prava povređeno možete podneti
          prigovor Povereniku za informacije od javnog značaja i zaštitu
          podataka o ličnosti, ul. Bulevar Kralja Aleksandra br. 15, 11 120
          Beograd, e-mail adresa office@poverenik.rs.
        </p>
        <H2>7. Kontakt za zaštitu podataka o ličnosti</H2>
        <p>
          Zahtevi i upite koji se odnose na obradu i zaštitu ličnih podatka
          možete uputiti na adresu elektronske pošte{' '}
          <Link to="mailto:aco@aco.rs">aco@aco.rs</Link>
          <br />
          <br />
          U skladu sa važećim zakonskim propisima kojima je regulisana zaštita
          podataka o ličnosti svaki zahtev/upit biće rešen u roku od 30 dana od
          dana prijema zahteva, s tim što rok može biti produžen za 60 dana
          ukoliko je to neophodno, uzimajući u obzir složenost i broj zahteva.
          <br />
          <br />
          Podaci za kontakt službenika za zaštitu podataka o ličnosti:
          <br />
          <br />
          Josipović Filip
          <br />
          ACO Građevinski elementi d.o.o.
          <br />
          III Industrijska zona bb, Krnješevci
          <br />
          Tel. +381 22 811 580
          <br />
          Fax. +381 22 811 590
          <br />
          Mob. +381 63 469 203
          <br />
          e-mail adresa:{' '}
          <Link to="mailto: josipovic@aco.rs">josipovic@aco.rs</Link>
        </p>
        <H3>Kontakt</H3>
        <br />
        <br />
        <p>
          Možete nas kontaktirati putem naše kartice kontakt koja se nalazi na
          našoj internet stranici{' '}
          <Link to="https://www.aco.rs/kontakt">
            (https://www.aco.rs/kontakt)
          </Link>
          . Našu karticu kontakt koristite slanjem Vašeg upita putem svoje
          e-mail adrese, na našu e-mail adresu{' '}
          <Link to="mailto:aco@aco.rs">aco@aco.rs</Link> . Možete nam dostaviti
          i dodatne informacije, ali to niste dužni učiniti.
        </p>
        <H3>Saglasnost</H3>
        <br />
        <br />
        <p>
          Slanjem ovog upita pristajete na elektronsko prikupljanje i čuvanje
          vaših podataka o ličnosti. Pravni osnov za obradu je čl. 12 st. 1.
          tačka 1. Zakona o zaštiti podataka o ličnosti. Vaše podatke koristimo
          isključivo za obradu Vašeg zahteva. Saglasnost možete povući u bilo
          kom trenutku, slanjem zahteva na e-mail adresu{' '}
          <Link to="mailto:josipovic@aco.rs">josipovic@aco.rs</Link> .
        </p>
        <H3>Facebook stranica kompanije</H3>
        <br />
        <br />
        <p>
          Radimo na zvaničnoj facebook stranici zasnovanoj na čl. 12. st. 1.
          tačka 1. Zakona o zaštiti podataka o ličnosti pod url adresom{' '}
          <Link to="https://www.facebook.com/ACO.rscg">
            https://www.facebook.com/ACO.rscg
          </Link>{' '}
          . Nikada ne prikupljamo, ne skladištimo I ne obrađujemo lične podatke
          naših korisnika na ovoj internet stranici. Podatke koje unesete na
          našu Facebook stranicu, kao što su komentari, video zapisi ili slike,
          ni u kom trenutku nećemo koristiti ili obrađivati u bilo koje druge
          svrhe.
          <br />
          <br />
          Facebook koristi metode praćenja na ovoj internet stranici. Imajte na
          umu da Facebook može da koristi podatke vašeg profila za procenu vaših
          navika, ličnih odnosa, sklonosti itd. Nemamo uticaj na obradu vaših
          podataka od strane Facebook-a.
        </p>
        <H3>Youtube video snimci</H3>
        <br />
        <br />
        <p>
          Na neke od naših internet stranica postavljamo Youtube video snimke.
          Otvaranje ovih internet stranica dovodi do preuzimanja Youtube
          sadržaja. S tim u vezi, Youtube takođe prima vašu IP adresu, koja je
          neophodna za preuzimanje sadržaja. Nemamo uticaj na dalju obradu od
          strane Youtube-a.
        </p>
        <H3>Linkedin stranica kompanije</H3>
        <br />
        <br />
        <p>
          Bliže podatke o radu naše kompanije možete dobiti uvidom na zvanični
          sajt Linkedin stranice kompanije zasnovanoj na čl. 12. st. 1. tačka 1.
          Zakona o zaštiti podataka o ličnosti pod url adresom{' '}
          <Link to="https://www.linkedin.com/company/aco-serbia-montenegro">
            https://www.linkedin.com/company/aco-serbia-montenegro
          </Link>{' '}
          . Nikada ne prikupljamo, ne skladištimo I ne obrađujemo lične podatke
          naših korisnika na ovoj internet stranici. Podatke koje unesete
          prilikom pristupa na našu Linkedin stranicu, ni u kom trenutku nećemo
          koristiti, ili obrađivati u bilo koje druge svrhe.
          <br />
          <br />
          Linkedin koristi metode praćenja na ovoj internet stranici. Imajte na
          umu da Linkedin može da koristi podatke vašeg profila za procenu vaših
          profesionalnih sposobnosti, ličnih odnosa, sklonosti itd. Nemamo
          uticaj na obradu vaših podataka od strane Linkedin-a.
        </p>
        <H3>Instagram stranica kompanije</H3>
        <br />
        <br />
        <p>
          Koristimo i našu instagram stranicu, u skladu sa čl. 12. st. 1. tačka
          1. Zakona o zaštiti podataka o ličnosti pod url adresom{' '}
          <Link to="https://www.instagram.com/aco.srb.mne">
            https://www.instagram.com/aco.srb.mne
          </Link>{' '}
          . Nikada ne prikupljamo, ne skladištimo i ne obrađujemo lične podatke
          naših korisnika na ovoj internet stranici. Podatke koje unesete na
          našu Instagram stranicu, kao što su komentari, video zapisi ili slike,
          ni u kom trenutku nećemo koristiti ili obrađivati u bilo koje druge
          svrhe.
          <br />
          <br />
          Instagram koristi metode praćenja na ovoj internet stranici. Imajte na
          umu da Instagram može da koristi podatke vašeg profila za procenu
          vaših navika, ličnih odnosa, sklonosti itd.
        </p>
        <br />
        <br />
        <BackLink to="/">Nazad na početnu</BackLink>
      </Content>
      <Footer>
        <FooterContent>
          <FooterLeft>
            <Contact
              onClick={() => {
                navigate('/dobro-dosli/');
              }}
            >
              {data.contactLabel}
            </Contact>
            <FooterInfo />
          </FooterLeft>
          <Social />
        </FooterContent>
      </Footer>
    </Wrapper>
  </Layout>
);

export default PrivacyPage;
