import { graphql, useStaticQuery } from 'gatsby';

type SiteMetadataReturn = {
  siteTitle: string;
  siteTitleAlt: string;
  siteUrl: string;
  siteDescription: string;
  siteBanner: string;
};

export function useSiteMetadata(): SiteMetadataReturn {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteTitleAlt
          siteUrl
          siteDescription
          siteBanner
        }
      }
    }
  `);

  return data.site.siteMetadata;
}
