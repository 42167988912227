import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Minus: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => {
    return (
      <svg
        width="1em"
        height="1em"
        aria-hidden="true"
        viewBox="0 0 448 512"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
      >
        <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
      </svg>
    );
  }
);
