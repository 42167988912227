import { graphql, PageProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Background } from '~src/components/background';
import { Link } from '~src/components/link';
import { LogoSVG } from '~src/components/logo-svg';
import { ThreeSixtySVG } from '~src/components/three-sixty-svg';
import { useMedia, useUpdateEffect } from '~src/hooks';
import Layout from '~src/layouts/page-layout';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  ${p =>
    p.theme.maxWidth.desktop`
    flex-direction: column-reverse;

    @media (orientation:landscape) {
     flex-direction: row;
    }
  `};
`;

const SideContent = styled.div`
  font-size: 1em;
  width: 34%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-right: 1.5em;
  ${p =>
    p.theme.maxWidth.desktop`
    width: 100%;

    @media (orientation:landscape) {
      width: 45%;
      font-size: 0.8em;
    }
  `};
`;

const SideBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextBox = styled.div`
  width: 22em;
  height: 22em;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  ${p => p.theme.maxWidth.tablet`
    width: 24em;

    @media (orientation:landscape) {
      width: auto;
    }
  `}
  ${p => p.theme.maxWidth.small`
    margin: 2em 0;
  `}
`;

const Message = styled.span`
  font-size: 1.75em;
  letter-spacing: 0.2em;
  line-height: 1.35;
  margin-bottom: 0.25em;
  text-transform: uppercase;
`;

const Title = styled.h1`
  letter-spacing: 0.2em;
  line-height: 1.35;
  ${p => p.theme.maxWidth.small`
    margin-bottom: 0;
  `}
`;

const CTALink = styled(Link)`
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.3;
  text-transform: uppercase;
  color: #000;
  padding: 0.75em 2em;
  border: solid 0.5px #000;
  margin-top: auto;
  text-align: center;
  width: fit-content;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #000;
  }

  ${p => p.theme.maxWidth.tablet`
    font-size: 1.3em;
  `}
  ${p => p.theme.maxWidth.small`
    margin-top: 1em;
  `}
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 16em;
  ${p => p.theme.maxWidth.desktop`
    max-width: 17em;
  `}

  > svg {
    width: 6em;
    height: 4.625em;
  }
`;

const FooterMessage = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  height: 2rem;
  margin-top: auto;
`;

const ThreeSixty = styled(Link)`
  position: absolute;
  bottom: 1.75em;
  right: 1.8em;

  > svg {
    width: 4.6em;
    height: 4.6em;

    #threeSixtyContainer,
    #threeSixtyShape {
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover {
    > svg {
      #threeSixtyContainer {
        opacity: 1;
      }
      #threeSixtyShape {
        fill: ${p => p.theme.colors.blueLight};
      }
    }
  }
`;

const copy = {
  message: `Dobrodošli u`,
  title: `Aco izložbeni prostor`,
  cta: `Započnite obilazak`,
};

type Data = {
  coverImage: {
    childImageSharp: {
      fluid: FluidObject | FluidObject[];
    };
  };
};

const seoData = {
  title: 'ACO izložbeni prostor',
};

const IndexPage: FC<PageProps<Data>> = ({ location, data }) => {
  const isLandscape = useMedia(`(orientation: landscape)`);

  // Problem with IOS not capturing width and hight after orientation change
  useUpdateEffect(() => {
    window.location.reload(false);
  }, [isLandscape]);

  return (
    <Layout seoData={seoData} pathname={location.pathname}>
      <Wrapper>
        <SideContent>
          <SideBox>
            <TextBox>
              <Message>{copy.message}</Message>
              <Title>{copy.title}</Title>
              <CTALink to="dobro-dosli">{copy.cta}</CTALink>
            </TextBox>
            <Footer>
              <LogoSVG />
              <FooterMessage>
                ACO. creating <br /> the future of drainage
              </FooterMessage>
            </Footer>
          </SideBox>
        </SideContent>
        <Background fluid={data.coverImage.childImageSharp.fluid}>
          <ThreeSixty to="izlozbeni-prostor">
            <ThreeSixtySVG />
          </ThreeSixty>
        </Background>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "homepage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, maxHeight: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
