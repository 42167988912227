import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Youtube: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        aria-hidden="true"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        className={className}
        fill="currentColor"
        {...props}
      >
        <path d="M10 9.1l5.1 2.9-5.1 2.9V9.1zm14-6.5v18.9c0 1.4-1.2 2.6-2.6 2.6H2.6C1.2 24 0 22.8 0 21.4V2.6C0 1.2 1.2 0 2.6 0h18.9C22.8 0 24 1.2 24 2.6zM21.8 12s0-3.2-.4-4.7c-.2-.8-.9-1.5-1.7-1.7-1.6-.5-7.7-.5-7.7-.5s-6.1 0-7.6.4c-.9.3-1.5.9-1.7 1.8-.4 1.5-.4 4.7-.4 4.7s0 3.2.4 4.7c.2.8.9 1.5 1.7 1.7 1.5.4 7.6.4 7.6.4s6.1 0 7.6-.4c.8-.2 1.5-.9 1.7-1.7.5-1.5.5-4.7.5-4.7z" />
      </svg>
    );
  }
);
