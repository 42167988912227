export const zIndexes = {
  hide: -1,
  auto: 'auto',
  base: 0,
  nav: 1,
  hamburger: 1,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  toast: 1700,
  tooltip: 1800,
  a11yIndicators: 10000,
};
