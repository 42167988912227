import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const ArrowDirection: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '', ...props }) => {
    return (
      <svg
        x="0px"
        y="0px"
        width="1em"
        height="1em"
        viewBox="0 0 60 100"
        xmlSpace="preserve"
        className={className}
        {...props}
      >
        <path
          id="arrow-inner"
          opacity={0.15}
          fill="#231f20"
          d="M60 31.2L30.8 0 0 31.2 15 31.2 6 100 54 100 45 31.2z"
        />
        <path
          d="M30.8 2.7l24.6 26.2h-12l.4 2.8 8.6 66H7.7l8.6-66 .4-2.8H4.9L30.8 2.7m0-2.7L0 31.2h15L6 100h48l-9-68.8h15L30.8 0z"
          fill="#231f20"
        />
      </svg>
    );
  }
);
