import React, { FC } from 'react';
import styled from 'styled-components';

import { Facebook, Instagram, Linkedin, Pinterest, Youtube } from './icons';
import { Link } from './link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > a {
    width: 2em;
    height: 2em;
    color: ${p => p.theme.colors.red};
    margin-left: 1em;

    &:hover {
      color: ${p => p.theme.colors.redDark};
    }

    > svg {
      width: 2em;
      height: 2em;
    }
  }
`;

const FacebookLink = styled(Link)``;
const InstagramLink = styled(Link)``;
const LinkedinLink = styled(Link)``;
const PinterestLink = styled(Link)``;
const YoutubeLink = styled(Link)``;

const data = {
  facebook: `https://www.facebook.com/ACO.rscg`,
  linkedin: `https://www.linkedin.com/company/aco-serbia-montenegro`,
  youtube: `https://www.youtube.com/channel/UCeQFAES_wDS7RWb_UIyozJw`,
  instagram: `https://www.instagram.com/aco.srb.mne/`,
  pinterest: `https://www.pinterest.com/aco_serbia_montenegro/`,
};

export const Social: FC = () => {
  return (
    <Wrapper>
      <FacebookLink to={data.facebook}>
        <Facebook />
      </FacebookLink>
      <LinkedinLink to={data.linkedin}>
        <Linkedin />
      </LinkedinLink>
      <YoutubeLink to={data.youtube}>
        <Youtube />
      </YoutubeLink>
      <InstagramLink to={data.instagram}>
        <Instagram />
      </InstagramLink>
      <PinterestLink to={data.pinterest}>
        <Pinterest />
      </PinterestLink>
    </Wrapper>
  );
};
