import { ApolloProvider } from '@apollo/client';
import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { createApolloClient } from '~src/apollo/client';
import { PortalManager } from '~src/components/portal';
import { MenuProvider } from '~src/context/menu-context';
import { theme } from '~src/styles';

const apolloClient = createApolloClient();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <PortalManager>
          <MenuProvider>{element}</MenuProvider>
        </PortalManager>
      </ThemeProvider>
    </ApolloProvider>
  );
};
